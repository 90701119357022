import { useMutation } from "@apollo/client";
import { PASSWORD_RESET_MUTATION } from "../../api/mutations/contact.mutations";
import { useParams, useNavigate } from "react-router-dom";

const ResetPasswordButton = ({ passwordFormState,isEnrollment }) => {
  const [passwordForm, setPasswordForm] = passwordFormState;
  const { password, confirmPassword } = passwordForm;
  const { token } = useParams();
  const navigate = useNavigate();
  const [resetPassword] = useMutation(PASSWORD_RESET_MUTATION);
  const handleClick = async () => {
    if (!token) return alert("Invalid token.");
    if (!password || !confirmPassword)
      return alert("Please enter a password and confirm it.");
    if (password !== confirmPassword) return alert("Passwords do not match.");
    try {
      const response = await resetPassword({
        variables: { password, token },
      });
      if (response?.error) throw new Error(response.error);
      alert(response.data.passwordReset);
      //console.log(response);
      navigate("/login");
    } catch (error) {
      error?.message ? alert(error.message) : alert("Contact system admin.");
    }
  };
  return (
    <button
      className="border border-codiRed hover:bg-white bg-codiRed text-white hover:text-codiRed font-bold py-2 px-8 rounded-lg"
      onClick={handleClick}
    >
     {isEnrollment ? "Create Password": "Reset Password"}
    </button>
  );
};

export default ResetPasswordButton;
