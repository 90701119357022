import DefaultLayout from "../layouts/default.layout";
import pdfurl from './../terms_and_conditions.pdf';

const TermsConditions = () => {

  return (
        <iframe
            style={{height:'100vh'}}
            src={pdfurl}
            width="100%"
            height="100%"
            loading="lazy"
            title="PDF-file"
        ></iframe>
  );
};

export default TermsConditions;
